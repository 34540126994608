<template>
  <div>
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group
            label="Full Name Or Legal Name"
            label-for="legalNameOrFullName"
          >
            <b-form-input v-model="data.brandName" disabled />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Contact Person" label-for="contactPerson">
            <b-form-input v-model="data.contactPerson" disabled />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Contact Person Position"
            label-for="contactPersonPosition"
          >
            <b-form-input v-model="data.contactPersonPosition" disabled />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Contact Person Mobile"
            label-for="contactPersonMobile"
          >
            <b-form-input v-model="data.contactPersonMobile" disabled />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Brand Name" label-for="brandName">
            <b-form-input v-model="data.brandName" disabled />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Legal Address" label-for="legalAddress">
            <b-form-input v-model="data.legalAddress" disabled />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Physical Address" label-for="physicalAddress">
            <b-form-input v-model="data.physicalAddress" disabled />
          </b-form-group>
        </b-col>
        <b-col sm="6" v-if="data.vendorType === 1">
          <b-form-group label="Director Full Name" label-for="directorFullName">
            <b-form-input v-model="data.directorFullName" disabled />
          </b-form-group>
        </b-col>
        <b-col v-if="data.vendorType === 1" sm="6">
          <b-form-group
            label="Director Mobile Number"
            label-for="directorMobile"
          >
            <b-form-input v-model="data.directorMobile" disabled />
          </b-form-group>
        </b-col>
        <b-col v-if="data.vendorType === 1" sm="6">
          <b-form-group
            label="Accountant Full Name"
            label-for="accountantFullName"
          >
            <b-form-input v-model="data.accountantFullName" disabled />
          </b-form-group>
        </b-col>
        <b-col sm="6" v-if="data.vendorType === 1">
          <b-form-group
            label="Accountant Mobile Number"
            label-for="accountantMobile"
          >
            <b-form-input v-model="data.accountantMobile" disabled />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="iBan" label-for="iban">
            <b-form-input v-model="data.iban" disabled />
          </b-form-group>
          <b-form-checkbox
            v-model="data.isVatPayer"
            :value="data.isVatPayer"
            disabled
          >
            Is Vat Payer
          </b-form-checkbox>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Other Contact Person Full name"
            label-for="otherContactPersonFullName"
          >
            <b-form-input v-model="data.otherContactPersonFullName" disabled />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Other Contact Person Mobile Number"
            label-for="otherContactPersonMobile"
          >
            <b-form-input v-model="data.otherContactPersonMobile" disabled />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Warehouse Address" label-for="warehouseAddress">
            <b-form-input v-model="data.warehouseAddress" disabled />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Web Page" label-for="webPage">
            <b-form-input v-model="data.webPage" disabled />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Facebook" label-for="facebookLink">
            <b-form-input v-model="data.facebookLink" disabled />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Instagram" label-for="instagramLink">
            <b-form-input v-model="data.instagramLink" disabled />
          </b-form-group>
        </b-col>
        <div class="last-items">
          <b-col>
            <b-form-group label="Description" label-for="logo">
              <b-form-textarea
                id="textarea-default"
                v-model="data.description"
                cols="3"
                disabled
              />
            </b-form-group>
          </b-col>
          <div class="branches-segments-wrapper">
            <b-col>
              <b-form-group label="Branches" label-for="branches">
                <b-list-group v-for="branch in data.branches" :key="branch.id">
                  <b-list-group-item class="mb-1">{{
                    branch.nameEng
                  }}</b-list-group-item>
                </b-list-group>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Segments" label-for="segments">
                <b-list-group
                  v-for="segment in data.vendorSegments"
                  :key="segment.id"
                >
                  <b-list-group-item class="mb-1">
                    <span>{{
                      `${segment.categoryNameEng}/${segment.subCategoryNameEng}`
                    }}</span>
                  </b-list-group-item>
                </b-list-group>
              </b-form-group>
            </b-col>
          </div>
        </div>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BForm,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormCheckbox,
  BRow,
  BCol,
} from "bootstrap-vue";

export default {
  components: {
    BForm,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BRow,
    BCol,
  },
  props: {
    data: {},
  },
};
</script>
<style lang="scss" scoped>
.last-items {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.branches-segments-wrapper {
  display: flex;
}
</style>
