<template>
	<div>
		<b-modal
			v-model="showVendorInfo"
			size="lg"
			centered
			hide-footer
			@hide="hidePopup"
		>
			<ApplicationInputs :data="selectedVendor" />
		</b-modal>

		<b-card>
			<div class="custom-search">
				<b-form-group>
					<div class="d-flex align-items-center">
						<b-form-input
							v-model="searchTerm"
							placeholder="Search"
							type="text"
							class="d-inline-block"
						/>
					</div>
				</b-form-group>
			</div>
			<!-- table -->
			<b-table
				:fields="columns"
				:items="vendorsList"
				class="shadow-sm rounded"
				small
				responsive
				hover
				bordered
				:busy="isTableBusy"
				empty-text="No matching records found"
				@row-clicked="onRowClick"
			>
				<template #table-busy>
					<div class="text-center text-danger my-5">
						<b-spinner class="align-middle"></b-spinner>
						<strong>Loading...</strong>
					</div>
				</template>
				<!-- Column: Action -->
				<template #cell(action)="data">
					<span>
						<input
							:ref="`${data.item.id}-file`"
							type="file"
							style="display: none"
							accept="application/pdf"
							@change="(e) => onFilePicked(e, data.item.id)"
						/>
						<b-button
							v-b-tooltip.hover.bottom="'Contract upload'"
							class="btn-icon"
							variant="flat-primary"
							@click="onPickFile(data.item.id)"
						>
							<feather-icon size="16" icon="UploadIcon" />
						</b-button>
						<b-button
							v-b-tooltip.hover.bottom="'Attachment download'"
							class="btn-icon"
							variant="flat-primary"
							@click="onGetAttachement(data.item.id)"
						>
							<feather-icon size="16" icon="PaperclipIcon" />
						</b-button>
						<b-button
							v-b-tooltip.hover.bottom="'Password reset'"
							class="btn-icon"
							variant="flat-success"
							@click="onResetPassword(data.item.id)"
						>
							<feather-icon size="16" icon="KeyIcon" />
						</b-button>
						<b-button
							v-b-tooltip.hover.bottom="'Delete'"
							variant="flat-danger"
							class="btn-icon"
							@click="onDeleteVendor(data.item.id)"
						>
							<feather-icon size="16" icon="TrashIcon" />
						</b-button>
						<b-button
							v-b-tooltip.hover.bottom="'Impersonate'"
							class="btn-icon"
							variant="flat-info"
							@click="impersonate(data.item.email)"
						>
							<feather-icon size="16" icon="UserIcon" />
						</b-button>
					</span>
				</template>
			</b-table>
		</b-card>
	</div>
</template>

<script>
import {
	BFormGroup,
	BFormInput,
	BButton,
	BTable,
	BCard,
	VBTooltip,
	BSpinner,
} from 'bootstrap-vue';
// import AddUserForm from '@/views/components/AddUserForm/AddUserForm.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

import { mapActions, mapState } from 'vuex';
import 'vue-good-table/dist/vue-good-table.css';
import Swal from 'sweetalert2';
import store from '@/store/index';
import ApplicationInputs from './ApplicationInputs.vue';

export default {
	components: {
		// AddUserForm,
		BFormGroup,
		BFormInput,
		ApplicationInputs,
		BButton,
		BTable,
		BCard,
		BSpinner,
		// eslint-disable-next-line vue/no-unused-components
		ToastificationContent,
	},
	directives: {
		'b-tooltip': VBTooltip,
	},
	data() {
		return {
			isTableBusy: false,
			vendorsList: [],
			pageLength: 10,
			action: null,
			selectedVendor: {},
			showVendorInfo: false,
			applicationLogo: null,
			imageUrl: null,
			attachmentStatus: {
				0: 'Contract NOT uplpaded',
				1: 'Contract uploaded',
				2: 'Contract signed',
			},
			columns: [
				{
					label: 'id',
					key: 'id',
				},
				{
					label: 'Brand Name',
					key: 'brandName',
				},
				{
					label: 'Contact Person',
					key: 'contactPerson',
				},
				{
					label: 'Legal Address',
					key: 'legalAddress',
				},
				{
					label: 'Mobile',
					key: 'mobile',
				},
				{
					label: 'Status',
					key: 'contractAttachmentStatus',
					formatter: (value) => `${this.attachmentStatus[value]}`,
				},
				{
					label: 'Action',
					key: 'action',
				},
			],
			searchTerm: '',
		};
	},
	computed: {
		...mapState('vendorManagement', ['vendors', 'redirectUrl', 'contractData']),
		...mapState('accountSettings'),
		tableTheme() {
			if (store.state.appConfig.layout.skin === 'dark') {
				return 'nocturnal';
			}
			return '';
		},
	},
	created() {
		this.getVendors({ skip: 0, take: 10, searchKey: '' }).then(() => {
			this.vendorsList = this.vendors.data.vendors;
		});
	},
	methods: {
		...mapActions('vendorManagement', [
			'getVendors',
			'resetVendorPassword',
			'deleteVendor',
			'getImpersonateUrl',
			'setVendorAttachement',
			'getVendorAttachement',
		]),
		onPickFile(id) {
			this.$refs[`${id}-file`].click();
		},
		onFilePicked(event, id) {
			this.isTableBusy = true;
			const { files } = event.target;

			const fileReader = new FileReader();

			fileReader.addEventListener('load', () => {
				this.imageUrl = fileReader.result;
			});

			fileReader.readAsDataURL(files[0]);

			const formData = new FormData();

			formData.append('contractAttachment', files[0]);

			this.setVendorAttachement({ id, formData }).then(() => {
				// refetch vendors
				this.getVendors({ skip: 0, take: 10, searchKey: '' }).then(() => {
					this.vendorsList = this.vendors.data.vendors;
				});
				// set table back to not busy
				this.isTableBusy = false;

				// success messsage
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'File sucessfully uploaded',
						icon: 'FileIcon',
						variant: 'success',
					},
				});
			});
		},

		hidePopup() {
			this.showVendorInfo = false;
		},
		onRowClick(item) {
			this.selectedVendor = item;
			this.showVendorInfo = true;
		},
		onGetAttachement(id) {
			this.getVendorAttachement(id).then(() => {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'File sucessfully downloaded',
						icon: 'FileIcon',
						variant: 'success',
					},
				});
			});
		},
		impersonate(email) {
			console.log(email);
			this.getImpersonateUrl(email).then(() => {
				// const justHash = this.redirectUrl.split('club/')[1];
				// const localHost = `http://localhost:8081/${justHash}`;

				window.open(this.redirectUrl, '_blank');
			});
		},
		onDeleteVendor(vendorId) {
			Swal.fire({
				title: 'Are you sure?',
				text: "You won't be able to revert this",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#28a745',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!',
			}).then((result) => {
				if (result.isConfirmed) {
					this.deleteVendor({ id: vendorId }).then(() => {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: 'File sucessfully uploaded',
								icon: 'UserIcon',
								variant: 'success',
							},
						});
					});
					this.vendors.splice(
						this.vendors.findIndex((i) => i.id === vendorId),
						1
					);
				}
			});
		},
		onResetPassword(vendorId) {
			Swal.fire({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#28a745',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, reset it!',
			}).then((result) => {
				if (result.isConfirmed) {
					Swal.fire(
						'Reseted!',
						"Vendor's password has been reseted.",
						'success'
					);
					this.resetVendorPassword({ id: vendorId });
				}
			});
		},
	},
};
</script>
<style lang="scss" scoped>
.custom-search {
	display: flex;
	justify-content: flex-end;
	@media (max-width: 600px) {
		width: 100%;
		justify-content: flex-start;
	}
}
.form-group {
	@media (max-width: 600px) {
		width: 100%;
	}
}
.pointer {
	cursor: pointer;
}
</style>
